import * as React from 'react';
import { Router } from '@reach/router';
import ImpactHome from './impact-home';
import PostDetail from './posts-detail';

const Impact = () => {
  return (
    <div>
      <Router basepath="/">
        <ImpactHome path="/impact" />
        <PostDetail path="/impact/:id" />
      </Router>
    </div>
  );
};

export default Impact;
